breakpoint($size)
  if($size == 'xs')
    @media screen and (min-width 375px)
      {block}
  if($size == 'sm')
    @media screen and (min-width 768px)
      {block}
  else if ($size == 'md')
    @media screen and (min-width 992px)
      {block}
  else if ($size == 'lg')
    @media screen and (min-width 1200px)
      {block}
