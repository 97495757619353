@import '@mixins'

.p-blog-featured-list
  margin 0
  padding 0
  list-style-type none
  h3
    a
      color var(--pcolor-gray-darkest)
      font-size clamp(var(--pfont-size-md), 2.7vw, var(--pfont-size-xl))
      text-decoration none
  li
    &:not(:first-child)
      margin-top 40px
  time
    display block
    margin-top 20px
    font-size var(--pfont-size-xxs)
    font-weight 700
    text-transform uppercase

+breakpoint('sm')
  .p-blog-featured-list
    h3
      a
        &:focus
          outline 0
  .p-blog-featured-list-item
    padding 20px
    box-shadow 0 0 0 0 var(--pcolor-gray-lightest)
    border-radius 12px
    box-sizing border-box
    transition all 300ms ease-out
    &:has(h3 > a:focus)
      box-shadow 0 0 0 6px var(--pcolor-gray-lightest)
