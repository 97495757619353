@import '@mixins'

.p-home-view
  h2
    margin-bottom 40px
  ul
    + a
      margin-top 40px
  .p-home-view-project-list
    margin 0
    padding 0
    list-style-type none
    li
      & + li
        margin-top 20px

.p-home-hero-actions
    display flex
    flex-direction column
    justify-content center
    gap 20px
    margin-top 40px

.p-home-view-project-list
  .p-project-card-header-badge
    position relative
    padding-right 42px
    .p-icon
      position absolute
      right 0

.p-home-project-card-veedgee
  .p-project-card-header-badge
    color var(--pcolor-blue-dark)
    .p-icon
      top -20px
      width 48px
      height 48px
      [data-pstroke]
        stroke var(--pcolor-blue-dark)

.p-home-project-card-gdemo
  .p-project-card-header-badge
    color var(--pcolor-yellow-dark)
    .p-icon
      top -15px
      [data-pstroke]
        stroke var(--pcolor-yellow-dark)

.p-project-card-header-badge
  display none

.p-home-projects
  .p-button
    position relative

.p-home-projects-badge
    position absolute
    top -14px
    right 10px
    display flex
    align-items center
    justify-content center
    min-width 36px
    height 20px
    background var(--p-color-projects-bagdge-bg)
    color var(--p-color-projects-bagdge-text)
    font-size var(--pfont-size-xs)
    font-weight 500
    line-height 1
    letter-spacing -1px
    border-radius 12px

+breakpoint('xs')
  .p-home-hero-actions
    flex-direction row
    justify-content left
  .p-project-card-header-badge
    display flex
    align-items center
    font-size 9px
    font-weight bold
    text-transform uppercase

+breakpoint('sm')
  .p-home-view
    section
      &:not(:first-child)
        margin-top 160px
      h1, h2
        text-align center
    .p-home-hero
      padding-top 80px
      p
        margin 20px auto 0
        max-width 620px
        text-align center
    .p-project-card-header-badge
      font-size var(--pfont-size-xxxs)
    .p-home-view-project-list
      display grid
      grid-template-columns 1fr 1fr
      grid-gap 40px
      margin 20px 0
      max-width 860px
      li
        + li
          margin 0
      .p-project-card
        min-height 180px
    .p-blog-featured-list
      display grid
      grid-template-columns 1fr 1fr
      grid-gap 40px
      li
        + li
          margin 0
  .p-home-hero-actions
    justify-content center
  .p-home-projects,
  .p-home-blogs
    display flex
    flex-direction column
    align-items center

+breakpoint('md')
  .p-home-view
    section
      &:not(:first-child)
        margin-top 240px
    .p-home-view-project-list
      grid-template-columns 1fr 1fr
    .p-blog-featured-list
      grid-template-columns 1fr 1fr 1fr
    .p-home-view-project-list
      .p-project-card
        min-height 160px
